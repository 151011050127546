/*!
 * === UNIT =====================================
 */

.hero
    bg(center,cover);
    background-image: url('../images/hero-bg.jpg');


.labelUnder
    background: rgba(#fff,0.1);
    padding: 4px 8px 4px 4px;

    .v-icon
        background: rgba(#fff,0.3);
        fill: #fff;

        .ico
            width: 50%;


.featureCard
    box-shadow: 0 0 16px 0 rgba(#000, 0.04);

    &-txt
        color: rgba($colorText,0.4);

        strong
            color: rgba($colorText,0.88);
            font-weight: 600;

    .v-icon
        fill: rgba($colorText,0.88);
