/*
  BREAKPOINTS
  iPad Pro - 1366, 1024
  macbook old = 1280
  iPad - 1024, 768
  iPhone - 414, 375, 320

  note = 'all and (max-width: 1760px)'
  laptop = 'all and (max-width: 1439px)'
  tablet = 'all and (max-width: 1199px)'
  pad = 'all and (max-width: 1023px)'
  phone = 'all and (max-width: 767px)'
*/
/*
 * === FONTS ====================================
 */
@font-face {
  font-family: 'Inter';
  src: local('Inter Regular'), local('Inter-Regular'), url("../fonts/hinted-Inter-Regular.woff2") format('woff2'), url("../fonts/hinted-Inter-Regular.woff") format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Inter';
  src: local('Inter Semi Bold'), local('Inter-SemiBold'), url("../fonts/hinted-Inter-SemiBold.woff2") format('woff2'), url("../fonts/hinted-Inter-SemiBold.woff") format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/*
 * === BASE =====================================
 */
body,
html {
  border: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
html {
  font-size: 16px;
}
html.is-no-scroll,
html.is-unimodal-active {
  overflow: hidden;
}
body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  background-color: #fff;
  font-family: 'Inter', -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  color: #000;
  font-weight: normal;
  line-height: 1;
}
.browsehappy {
  display: block;
  margin: 0;
  padding: 30px;
  text-align: center;
  background: #e55454;
  color: #fff;
  font-size: 18px;
  line-height: 1.4;
}
.browsehappy a,
.browsehappy a:hover {
  color: #fff;
  text-decoration: underline;
}
/*
 * --- FOOTER TO BOTTOM -------------------------
 */
.main {
  flex: 1 1 auto;
  min-height: 1vh;
}
.header,
.footer {
  flex-shrink: 0;
}
/*
 * --- UI ELEMENTS ------------------------------
 */
a {
  font-size: inherit;
  font-family: inherit;
  text-decoration: none;
  color: inherit;
  transition: all 0.32s linear;
}
strong,
b {
  font-weight: bold;
}
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  display: block;
  width: auto;
  outline: none;
  line-height: 1;
  border: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(255,255,255,0);
  transition: all 0.32s linear;
}
button,
button[type='submit'],
input[type='submit'] {
  cursor: pointer;
}
input[type='search'],
input[type='number'] {
  -webkit-appearance: textfield;
}
input[type=search]::-ms-clear,
input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
button:disabled,
input:disabled,
textarea:disabled,
button.is-disabled,
input.is-disabled,
textarea.is-disabled {
  cursor: not-allowed;
}
textarea {
  width: 100%;
  resize: none;
}
fieldset {
  border: none;
  margin: 0;
  padding: 0;
}
picture {
  display: block;
}
img {
  width: 100%;
  display: block;
}
pre {
  font-size: 12px;
}
/*
 * === UI =======================================
 */
.is-loading {
  position: relative;
}
.is-loading:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255,0.5);
  z-index: 10;
}
.is-loading:after {
  content: '';
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px;
  border-width: 5px;
  border-color: #111827 #e5e7eb;
  border-style: solid;
  animation: loading-rotate 1s ease-in-out infinite forwards;
  z-index: 15;
}
/*
 * === FRAMEWORK ================================
 */
.v-box {
  width: 100%;
  max-width: 1168px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
}
.v-box.is-px-0 {
  padding-left: 0;
  padding-right: 0;
  max-width: 1136px;
}
/*
 * --- GRID -------------------------------------
 */
.v-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin-left: -8px;
  margin-right: -8px;
}
.v-col {
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}
.v-col-1 {
  max-width: 8.333333%;
}
.v-col-2 {
  max-width: 16.666666%;
}
.v-col-3 {
  max-width: 25%;
}
.v-col-4 {
  max-width: 33.333333%;
}
.v-col-5 {
  max-width: 41.666666%;
}
.v-col-6 {
  max-width: 50%;
}
.v-col-7 {
  max-width: 58.333333%;
}
.v-col-8 {
  max-width: 66.666666%;
}
.v-col-9 {
  max-width: 75%;
}
.v-col-10 {
  max-width: 83.333333%;
}
.v-col-11 {
  max-width: 91.666666%;
}
.v-col-12 {
  max-width: 100%;
}
@media all and (max-width: 1760px) {
  .v-col-1-nt {
    max-width: 8.333333%;
  }
  .v-col-2-nt {
    max-width: 16.666666%;
  }
  .v-col-3-nt {
    max-width: 25%;
  }
  .v-col-4-nt {
    max-width: 33.333333%;
  }
  .v-col-5-nt {
    max-width: 41.666666%;
  }
  .v-col-6-nt {
    max-width: 50%;
  }
  .v-col-7-nt {
    max-width: 58.333333%;
  }
  .v-col-8-nt {
    max-width: 66.666666%;
  }
  .v-col-9-nt {
    max-width: 75%;
  }
  .v-col-10-nt {
    max-width: 83.333333%;
  }
  .v-col-11-nt {
    max-width: 91.666666%;
  }
  .v-col-12-nt {
    max-width: 100%;
  }
}
@media all and (max-width: 1439px) {
  .v-col-1-lp {
    max-width: 8.333333%;
  }
  .v-col-2-lp {
    max-width: 16.666666%;
  }
  .v-col-3-lp {
    max-width: 25%;
  }
  .v-col-4-lp {
    max-width: 33.333333%;
  }
  .v-col-5-lp {
    max-width: 41.666666%;
  }
  .v-col-6-lp {
    max-width: 50%;
  }
  .v-col-7-lp {
    max-width: 58.333333%;
  }
  .v-col-8-lp {
    max-width: 66.666666%;
  }
  .v-col-9-lp {
    max-width: 75%;
  }
  .v-col-10-lp {
    max-width: 83.333333%;
  }
  .v-col-11-lp {
    max-width: 91.666666%;
  }
  .v-col-12-lp {
    max-width: 100%;
  }
}
@media all and (max-width: 1199px) {
  .v-col-1-tb {
    max-width: 8.333333%;
  }
  .v-col-2-tb {
    max-width: 16.666666%;
  }
  .v-col-3-tb {
    max-width: 25%;
  }
  .v-col-4-tb {
    max-width: 33.333333%;
  }
  .v-col-5-tb {
    max-width: 41.666666%;
  }
  .v-col-6-tb {
    max-width: 50%;
  }
  .v-col-7-tb {
    max-width: 58.333333%;
  }
  .v-col-8-tb {
    max-width: 66.666666%;
  }
  .v-col-9-tb {
    max-width: 75%;
  }
  .v-col-10-tb {
    max-width: 83.333333%;
  }
  .v-col-11-tb {
    max-width: 91.666666%;
  }
  .v-col-12-tb {
    max-width: 100%;
  }
}
@media all and (max-width: 1023px) {
  .v-col-1-pd {
    max-width: 8.333333%;
  }
  .v-col-2-pd {
    max-width: 16.666666%;
  }
  .v-col-3-pd {
    max-width: 25%;
  }
  .v-col-4-pd {
    max-width: 33.333333%;
  }
  .v-col-5-pd {
    max-width: 41.666666%;
  }
  .v-col-6-pd {
    max-width: 50%;
  }
  .v-col-7-pd {
    max-width: 58.333333%;
  }
  .v-col-8-pd {
    max-width: 66.666666%;
  }
  .v-col-9-pd {
    max-width: 75%;
  }
  .v-col-10-pd {
    max-width: 83.333333%;
  }
  .v-col-11-pd {
    max-width: 91.666666%;
  }
  .v-col-12-pd {
    max-width: 100%;
  }
}
@media all and (max-width: 767px) {
  .v-col-1-ph {
    max-width: 8.333333%;
  }
  .v-col-2-ph {
    max-width: 16.666666%;
  }
  .v-col-3-ph {
    max-width: 25%;
  }
  .v-col-4-ph {
    max-width: 33.333333%;
  }
  .v-col-5-ph {
    max-width: 41.666666%;
  }
  .v-col-6-ph {
    max-width: 50%;
  }
  .v-col-7-ph {
    max-width: 58.333333%;
  }
  .v-col-8-ph {
    max-width: 66.666666%;
  }
  .v-col-9-ph {
    max-width: 75%;
  }
  .v-col-10-ph {
    max-width: 83.333333%;
  }
  .v-col-11-ph {
    max-width: 91.666666%;
  }
  .v-col-12-ph {
    max-width: 100%;
  }
}
/*
 * --- HELPER CLASSES ---------------------------
 */
.v-d-flex {
  display: flex;
  flex-wrap: wrap;
}
.v-d-inline-flex {
  display: inline-flex;
  flex-wrap: wrap;
}
.v-flex-nowrap {
  flex-wrap: nowrap;
}
.v-flex-wrap {
  flex-wrap: wrap;
}
.v-flexgrow-1 {
  flex-grow: 1;
}
.v-d-block {
  display: block;
}
.v-d-inline-block {
  display: inline-block;
}
.v-d-inline {
  display: inline;
}
.v-d-none {
  display: none;
}
.v-d-visible {
  visibility: visible;
}
.v-d-hidden {
  visibility: hidden;
}
.v-ta-left {
  text-align: left;
}
.v-ta-center {
  text-align: center;
}
.v-ta-right {
  text-align: right;
}
.v-ai-flex-start {
  align-items: flex-start;
}
.v-jc-flex-start {
  justify-content: flex-start;
}
.v-as-flex-start {
  align-self: flex-start;
}
.v-ai-flex-end {
  align-items: flex-end;
}
.v-jc-flex-end {
  justify-content: flex-end;
}
.v-as-flex-end {
  align-self: flex-end;
}
.v-ai-center {
  align-items: center;
}
.v-jc-center {
  justify-content: center;
}
.v-as-center {
  align-self: center;
}
.v-jc-space-between {
  justify-content: space-between;
}
.v-jc-space-around {
  justify-content: space-around;
}
.v-flexdir-row {
  flex-direction: row;
}
.v-flexdir-row-reverse {
  flex-direction: row-reverse;
}
.v-flexdir-column {
  flex-direction: column;
}
.v-flexdir-column-reverse {
  flex-direction: column-reverse;
}
.v-pt-0 {
  padding-top: 0px;
}
.v-pb-0 {
  padding-bottom: 0px;
}
.v-pr-0 {
  padding-right: 0px;
}
.v-pl-0 {
  padding-left: 0px;
}
.v-mt-0 {
  margin-top: 0px;
}
.v-mb-0 {
  margin-bottom: 0px;
}
.v-ml-0 {
  margin-left: 0px;
}
.v-mr-0 {
  margin-right: 0px;
}
.v-pt-4 {
  padding-top: 4px;
}
.v-pb-4 {
  padding-bottom: 4px;
}
.v-pr-4 {
  padding-right: 4px;
}
.v-pl-4 {
  padding-left: 4px;
}
.v-mt-4 {
  margin-top: 4px;
}
.v-mb-4 {
  margin-bottom: 4px;
}
.v-ml-4 {
  margin-left: 4px;
}
.v-mr-4 {
  margin-right: 4px;
}
.v-pt-5 {
  padding-top: 5px;
}
.v-pb-5 {
  padding-bottom: 5px;
}
.v-pr-5 {
  padding-right: 5px;
}
.v-pl-5 {
  padding-left: 5px;
}
.v-mt-5 {
  margin-top: 5px;
}
.v-mb-5 {
  margin-bottom: 5px;
}
.v-ml-5 {
  margin-left: 5px;
}
.v-mr-5 {
  margin-right: 5px;
}
.v-pt-8 {
  padding-top: 8px;
}
.v-pb-8 {
  padding-bottom: 8px;
}
.v-pr-8 {
  padding-right: 8px;
}
.v-pl-8 {
  padding-left: 8px;
}
.v-mt-8 {
  margin-top: 8px;
}
.v-mb-8 {
  margin-bottom: 8px;
}
.v-ml-8 {
  margin-left: 8px;
}
.v-mr-8 {
  margin-right: 8px;
}
.v-pt-10 {
  padding-top: 10px;
}
.v-pb-10 {
  padding-bottom: 10px;
}
.v-pr-10 {
  padding-right: 10px;
}
.v-pl-10 {
  padding-left: 10px;
}
.v-mt-10 {
  margin-top: 10px;
}
.v-mb-10 {
  margin-bottom: 10px;
}
.v-ml-10 {
  margin-left: 10px;
}
.v-mr-10 {
  margin-right: 10px;
}
.v-pt-12 {
  padding-top: 12px;
}
.v-pb-12 {
  padding-bottom: 12px;
}
.v-pr-12 {
  padding-right: 12px;
}
.v-pl-12 {
  padding-left: 12px;
}
.v-mt-12 {
  margin-top: 12px;
}
.v-mb-12 {
  margin-bottom: 12px;
}
.v-ml-12 {
  margin-left: 12px;
}
.v-mr-12 {
  margin-right: 12px;
}
.v-pt-14 {
  padding-top: 14px;
}
.v-pb-14 {
  padding-bottom: 14px;
}
.v-pr-14 {
  padding-right: 14px;
}
.v-pl-14 {
  padding-left: 14px;
}
.v-mt-14 {
  margin-top: 14px;
}
.v-mb-14 {
  margin-bottom: 14px;
}
.v-ml-14 {
  margin-left: 14px;
}
.v-mr-14 {
  margin-right: 14px;
}
.v-pt-16 {
  padding-top: 16px;
}
.v-pb-16 {
  padding-bottom: 16px;
}
.v-pr-16 {
  padding-right: 16px;
}
.v-pl-16 {
  padding-left: 16px;
}
.v-mt-16 {
  margin-top: 16px;
}
.v-mb-16 {
  margin-bottom: 16px;
}
.v-ml-16 {
  margin-left: 16px;
}
.v-mr-16 {
  margin-right: 16px;
}
.v-pt-24 {
  padding-top: 24px;
}
.v-pb-24 {
  padding-bottom: 24px;
}
.v-pr-24 {
  padding-right: 24px;
}
.v-pl-24 {
  padding-left: 24px;
}
.v-mt-24 {
  margin-top: 24px;
}
.v-mb-24 {
  margin-bottom: 24px;
}
.v-ml-24 {
  margin-left: 24px;
}
.v-mr-24 {
  margin-right: 24px;
}
.v-pt-32 {
  padding-top: 32px;
}
.v-pb-32 {
  padding-bottom: 32px;
}
.v-pr-32 {
  padding-right: 32px;
}
.v-pl-32 {
  padding-left: 32px;
}
.v-mt-32 {
  margin-top: 32px;
}
.v-mb-32 {
  margin-bottom: 32px;
}
.v-ml-32 {
  margin-left: 32px;
}
.v-mr-32 {
  margin-right: 32px;
}
.v-pt-44 {
  padding-top: 44px;
}
.v-pb-44 {
  padding-bottom: 44px;
}
.v-pr-44 {
  padding-right: 44px;
}
.v-pl-44 {
  padding-left: 44px;
}
.v-mt-44 {
  margin-top: 44px;
}
.v-mb-44 {
  margin-bottom: 44px;
}
.v-ml-44 {
  margin-left: 44px;
}
.v-mr-44 {
  margin-right: 44px;
}
.v-pt-48 {
  padding-top: 48px;
}
.v-pb-48 {
  padding-bottom: 48px;
}
.v-pr-48 {
  padding-right: 48px;
}
.v-pl-48 {
  padding-left: 48px;
}
.v-mt-48 {
  margin-top: 48px;
}
.v-mb-48 {
  margin-bottom: 48px;
}
.v-ml-48 {
  margin-left: 48px;
}
.v-mr-48 {
  margin-right: 48px;
}
.v-pt-64 {
  padding-top: 64px;
}
.v-pb-64 {
  padding-bottom: 64px;
}
.v-pr-64 {
  padding-right: 64px;
}
.v-pl-64 {
  padding-left: 64px;
}
.v-mt-64 {
  margin-top: 64px;
}
.v-mb-64 {
  margin-bottom: 64px;
}
.v-ml-64 {
  margin-left: 64px;
}
.v-mr-64 {
  margin-right: 64px;
}
.v-pt-82 {
  padding-top: 82px;
}
.v-pb-82 {
  padding-bottom: 82px;
}
.v-pr-82 {
  padding-right: 82px;
}
.v-pl-82 {
  padding-left: 82px;
}
.v-mt-82 {
  margin-top: 82px;
}
.v-mb-82 {
  margin-bottom: 82px;
}
.v-ml-82 {
  margin-left: 82px;
}
.v-mr-82 {
  margin-right: 82px;
}
.v-pt-96 {
  padding-top: 96px;
}
.v-pb-96 {
  padding-bottom: 96px;
}
.v-pr-96 {
  padding-right: 96px;
}
.v-pl-96 {
  padding-left: 96px;
}
.v-mt-96 {
  margin-top: 96px;
}
.v-mb-96 {
  margin-bottom: 96px;
}
.v-ml-96 {
  margin-left: 96px;
}
.v-mr-96 {
  margin-right: 96px;
}
.v-mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.v-mx-auto-left {
  margin-left: auto;
  margin-right: initial;
}
.v-mx-auto-right {
  margin-left: initial;
  margin-right: left;
}
.v-mx-none {
  margin-left: initial;
  margin-right: initial;
}
.v-mx-0 {
  margin-left: 0;
  margin-right: 0;
}
.v-px-0 {
  padding-left: 0;
  padding-right: 0;
}
.v-w-100 {
  width: 100%;
}
.v-w-auto {
  width: auto;
  max-width: none;
}
.v-h-56 {
  height: 56px;
}
.v-h-100 {
  height: 100%;
}
.v-h-auto {
  height: auto;
}
.v-ws-nowrap {
  white-space: nowrap;
}
.v-ws-wrap {
  white-space: normal;
}
.v-fz-12 {
  font-size: 12px;
}
.v-fz-16 {
  font-size: 16px;
}
.v-fz-24 {
  font-size: 24px;
}
.v-fz-38 {
  font-size: 38px;
}
.v-fz-48 {
  font-size: 48px;
}
.v-pos-relative {
  position: relative;
}
.v-pos-absolute {
  position: absolute;
}
.v-pos-fixed {
  position: fixed;
}
.v-pos-initial {
  position: initial;
}
.v-zi-0 {
  z-index: 0;
}
.v-zi-5 {
  z-index: 5;
}
.v-zi-10 {
  z-index: 10;
}
.v-zi-15 {
  z-index: 15;
}
.v-zi-20 {
  z-index: 20;
}
.v-brad-1000 {
  border-radius: 1000px;
}
.v-brad-100 {
  border-radius: 100px;
}
.v-brad-24 {
  border-radius: 24px;
}
.v-brad-16 {
  border-radius: 16px;
}
.v-brad-12 {
  border-radius: 12px;
}
.v-brad-8 {
  border-radius: 8px;
}
.v-brad-4 {
  border-radius: 4px;
}
.v-icon-16 {
  width: 16px;
  height: 16px;
}
.v-icon-20 {
  width: 20px;
  height: 20px;
}
.v-icon-24 {
  width: 24px;
  height: 24px;
}
.v-icon-40 {
  width: 40px;
  height: 40px;
}
@media all and (max-width: 1760px) {
  .v-d-flex-nt {
    display: flex;
    flex-wrap: wrap;
  }
  .v-d-inline-flex-nt {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .v-flex-nowrap-nt {
    flex-wrap: nowrap;
  }
  .v-flex-wrap-nt {
    flex-wrap: wrap;
  }
  .v-flexgrow-1-nt {
    flex-grow: 1;
  }
  .v-d-block-nt {
    display: block;
  }
  .v-d-inline-block-nt {
    display: inline-block;
  }
  .v-d-inline-nt {
    display: inline;
  }
  .v-d-none-nt {
    display: none;
  }
  .v-d-visible-nt {
    visibility: visible;
  }
  .v-d-hidden-nt {
    visibility: hidden;
  }
  .v-ta-left-nt {
    text-align: left;
  }
  .v-ta-center-nt {
    text-align: center;
  }
  .v-ta-right-nt {
    text-align: right;
  }
  .v-ai-flex-start-nt {
    align-items: flex-start;
  }
  .v-jc-flex-start-nt {
    justify-content: flex-start;
  }
  .v-as-flex-start-nt {
    align-self: flex-start;
  }
  .v-ai-flex-end-nt {
    align-items: flex-end;
  }
  .v-jc-flex-end-nt {
    justify-content: flex-end;
  }
  .v-as-flex-end-nt {
    align-self: flex-end;
  }
  .v-ai-center-nt {
    align-items: center;
  }
  .v-jc-center-nt {
    justify-content: center;
  }
  .v-as-center-nt {
    align-self: center;
  }
  .v-jc-space-between-nt {
    justify-content: space-between;
  }
  .v-jc-space-around-nt {
    justify-content: space-around;
  }
  .v-flexdir-row-nt {
    flex-direction: row;
  }
  .v-flexdir-row-reverse-nt {
    flex-direction: row-reverse;
  }
  .v-flexdir-column-nt {
    flex-direction: column;
  }
  .v-flexdir-column-reverse-nt {
    flex-direction: column-reverse;
  }
  .v-pt-0-nt {
    padding-top: 0px;
  }
  .v-pb-0-nt {
    padding-bottom: 0px;
  }
  .v-pr-0-nt {
    padding-right: 0px;
  }
  .v-pl-0-nt {
    padding-left: 0px;
  }
  .v-mt-0-nt {
    margin-top: 0px;
  }
  .v-mb-0-nt {
    margin-bottom: 0px;
  }
  .v-ml-0-nt {
    margin-left: 0px;
  }
  .v-mr-0-nt {
    margin-right: 0px;
  }
  .v-pt-4-nt {
    padding-top: 4px;
  }
  .v-pb-4-nt {
    padding-bottom: 4px;
  }
  .v-pr-4-nt {
    padding-right: 4px;
  }
  .v-pl-4-nt {
    padding-left: 4px;
  }
  .v-mt-4-nt {
    margin-top: 4px;
  }
  .v-mb-4-nt {
    margin-bottom: 4px;
  }
  .v-ml-4-nt {
    margin-left: 4px;
  }
  .v-mr-4-nt {
    margin-right: 4px;
  }
  .v-pt-5-nt {
    padding-top: 5px;
  }
  .v-pb-5-nt {
    padding-bottom: 5px;
  }
  .v-pr-5-nt {
    padding-right: 5px;
  }
  .v-pl-5-nt {
    padding-left: 5px;
  }
  .v-mt-5-nt {
    margin-top: 5px;
  }
  .v-mb-5-nt {
    margin-bottom: 5px;
  }
  .v-ml-5-nt {
    margin-left: 5px;
  }
  .v-mr-5-nt {
    margin-right: 5px;
  }
  .v-pt-8-nt {
    padding-top: 8px;
  }
  .v-pb-8-nt {
    padding-bottom: 8px;
  }
  .v-pr-8-nt {
    padding-right: 8px;
  }
  .v-pl-8-nt {
    padding-left: 8px;
  }
  .v-mt-8-nt {
    margin-top: 8px;
  }
  .v-mb-8-nt {
    margin-bottom: 8px;
  }
  .v-ml-8-nt {
    margin-left: 8px;
  }
  .v-mr-8-nt {
    margin-right: 8px;
  }
  .v-pt-10-nt {
    padding-top: 10px;
  }
  .v-pb-10-nt {
    padding-bottom: 10px;
  }
  .v-pr-10-nt {
    padding-right: 10px;
  }
  .v-pl-10-nt {
    padding-left: 10px;
  }
  .v-mt-10-nt {
    margin-top: 10px;
  }
  .v-mb-10-nt {
    margin-bottom: 10px;
  }
  .v-ml-10-nt {
    margin-left: 10px;
  }
  .v-mr-10-nt {
    margin-right: 10px;
  }
  .v-pt-12-nt {
    padding-top: 12px;
  }
  .v-pb-12-nt {
    padding-bottom: 12px;
  }
  .v-pr-12-nt {
    padding-right: 12px;
  }
  .v-pl-12-nt {
    padding-left: 12px;
  }
  .v-mt-12-nt {
    margin-top: 12px;
  }
  .v-mb-12-nt {
    margin-bottom: 12px;
  }
  .v-ml-12-nt {
    margin-left: 12px;
  }
  .v-mr-12-nt {
    margin-right: 12px;
  }
  .v-pt-14-nt {
    padding-top: 14px;
  }
  .v-pb-14-nt {
    padding-bottom: 14px;
  }
  .v-pr-14-nt {
    padding-right: 14px;
  }
  .v-pl-14-nt {
    padding-left: 14px;
  }
  .v-mt-14-nt {
    margin-top: 14px;
  }
  .v-mb-14-nt {
    margin-bottom: 14px;
  }
  .v-ml-14-nt {
    margin-left: 14px;
  }
  .v-mr-14-nt {
    margin-right: 14px;
  }
  .v-pt-16-nt {
    padding-top: 16px;
  }
  .v-pb-16-nt {
    padding-bottom: 16px;
  }
  .v-pr-16-nt {
    padding-right: 16px;
  }
  .v-pl-16-nt {
    padding-left: 16px;
  }
  .v-mt-16-nt {
    margin-top: 16px;
  }
  .v-mb-16-nt {
    margin-bottom: 16px;
  }
  .v-ml-16-nt {
    margin-left: 16px;
  }
  .v-mr-16-nt {
    margin-right: 16px;
  }
  .v-pt-24-nt {
    padding-top: 24px;
  }
  .v-pb-24-nt {
    padding-bottom: 24px;
  }
  .v-pr-24-nt {
    padding-right: 24px;
  }
  .v-pl-24-nt {
    padding-left: 24px;
  }
  .v-mt-24-nt {
    margin-top: 24px;
  }
  .v-mb-24-nt {
    margin-bottom: 24px;
  }
  .v-ml-24-nt {
    margin-left: 24px;
  }
  .v-mr-24-nt {
    margin-right: 24px;
  }
  .v-pt-32-nt {
    padding-top: 32px;
  }
  .v-pb-32-nt {
    padding-bottom: 32px;
  }
  .v-pr-32-nt {
    padding-right: 32px;
  }
  .v-pl-32-nt {
    padding-left: 32px;
  }
  .v-mt-32-nt {
    margin-top: 32px;
  }
  .v-mb-32-nt {
    margin-bottom: 32px;
  }
  .v-ml-32-nt {
    margin-left: 32px;
  }
  .v-mr-32-nt {
    margin-right: 32px;
  }
  .v-pt-44-nt {
    padding-top: 44px;
  }
  .v-pb-44-nt {
    padding-bottom: 44px;
  }
  .v-pr-44-nt {
    padding-right: 44px;
  }
  .v-pl-44-nt {
    padding-left: 44px;
  }
  .v-mt-44-nt {
    margin-top: 44px;
  }
  .v-mb-44-nt {
    margin-bottom: 44px;
  }
  .v-ml-44-nt {
    margin-left: 44px;
  }
  .v-mr-44-nt {
    margin-right: 44px;
  }
  .v-pt-48-nt {
    padding-top: 48px;
  }
  .v-pb-48-nt {
    padding-bottom: 48px;
  }
  .v-pr-48-nt {
    padding-right: 48px;
  }
  .v-pl-48-nt {
    padding-left: 48px;
  }
  .v-mt-48-nt {
    margin-top: 48px;
  }
  .v-mb-48-nt {
    margin-bottom: 48px;
  }
  .v-ml-48-nt {
    margin-left: 48px;
  }
  .v-mr-48-nt {
    margin-right: 48px;
  }
  .v-pt-64-nt {
    padding-top: 64px;
  }
  .v-pb-64-nt {
    padding-bottom: 64px;
  }
  .v-pr-64-nt {
    padding-right: 64px;
  }
  .v-pl-64-nt {
    padding-left: 64px;
  }
  .v-mt-64-nt {
    margin-top: 64px;
  }
  .v-mb-64-nt {
    margin-bottom: 64px;
  }
  .v-ml-64-nt {
    margin-left: 64px;
  }
  .v-mr-64-nt {
    margin-right: 64px;
  }
  .v-pt-82-nt {
    padding-top: 82px;
  }
  .v-pb-82-nt {
    padding-bottom: 82px;
  }
  .v-pr-82-nt {
    padding-right: 82px;
  }
  .v-pl-82-nt {
    padding-left: 82px;
  }
  .v-mt-82-nt {
    margin-top: 82px;
  }
  .v-mb-82-nt {
    margin-bottom: 82px;
  }
  .v-ml-82-nt {
    margin-left: 82px;
  }
  .v-mr-82-nt {
    margin-right: 82px;
  }
  .v-pt-96-nt {
    padding-top: 96px;
  }
  .v-pb-96-nt {
    padding-bottom: 96px;
  }
  .v-pr-96-nt {
    padding-right: 96px;
  }
  .v-pl-96-nt {
    padding-left: 96px;
  }
  .v-mt-96-nt {
    margin-top: 96px;
  }
  .v-mb-96-nt {
    margin-bottom: 96px;
  }
  .v-ml-96-nt {
    margin-left: 96px;
  }
  .v-mr-96-nt {
    margin-right: 96px;
  }
  .v-mx-auto-nt {
    margin-left: auto;
    margin-right: auto;
  }
  .v-mx-auto-left-nt {
    margin-left: auto;
    margin-right: initial;
  }
  .v-mx-auto-right-nt {
    margin-left: initial;
    margin-right: left;
  }
  .v-mx-none-nt {
    margin-left: initial;
    margin-right: initial;
  }
  .v-mx-0-nt {
    margin-left: 0;
    margin-right: 0;
  }
  .v-px-0-nt {
    padding-left: 0;
    padding-right: 0;
  }
  .v-w-100-nt {
    width: 100%;
  }
  .v-w-auto-nt {
    width: auto;
    max-width: none;
  }
  .v-h-56-nt {
    height: 56px;
  }
  .v-h-100-nt {
    height: 100%;
  }
  .v-h-auto-nt {
    height: auto;
  }
  .v-ws-nowrap-nt {
    white-space: nowrap;
  }
  .v-ws-wrap-nt {
    white-space: normal;
  }
  .v-fz-12-nt {
    font-size: 12px;
  }
  .v-fz-16-nt {
    font-size: 16px;
  }
  .v-fz-24-nt {
    font-size: 24px;
  }
  .v-fz-38-nt {
    font-size: 38px;
  }
  .v-fz-48-nt {
    font-size: 48px;
  }
  .v-pos-relative-nt {
    position: relative;
  }
  .v-pos-absolute-nt {
    position: absolute;
  }
  .v-pos-fixed-nt {
    position: fixed;
  }
  .v-pos-initial-nt {
    position: initial;
  }
  .v-zi-0-nt {
    z-index: 0;
  }
  .v-zi-5-nt {
    z-index: 5;
  }
  .v-zi-10-nt {
    z-index: 10;
  }
  .v-zi-15-nt {
    z-index: 15;
  }
  .v-zi-20-nt {
    z-index: 20;
  }
  .v-brad-1000-nt {
    border-radius: 1000px;
  }
  .v-brad-100-nt {
    border-radius: 100px;
  }
  .v-brad-24-nt {
    border-radius: 24px;
  }
  .v-brad-16-nt {
    border-radius: 16px;
  }
  .v-brad-12-nt {
    border-radius: 12px;
  }
  .v-brad-8-nt {
    border-radius: 8px;
  }
  .v-brad-4-nt {
    border-radius: 4px;
  }
  .v-icon-16-nt {
    width: 16px;
    height: 16px;
  }
  .v-icon-20-nt {
    width: 20px;
    height: 20px;
  }
  .v-icon-24-nt {
    width: 24px;
    height: 24px;
  }
  .v-icon-40-nt {
    width: 40px;
    height: 40px;
  }
}
@media all and (max-width: 1439px) {
  .v-d-flex-lp {
    display: flex;
    flex-wrap: wrap;
  }
  .v-d-inline-flex-lp {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .v-flex-nowrap-lp {
    flex-wrap: nowrap;
  }
  .v-flex-wrap-lp {
    flex-wrap: wrap;
  }
  .v-flexgrow-1-lp {
    flex-grow: 1;
  }
  .v-d-block-lp {
    display: block;
  }
  .v-d-inline-block-lp {
    display: inline-block;
  }
  .v-d-inline-lp {
    display: inline;
  }
  .v-d-none-lp {
    display: none;
  }
  .v-d-visible-lp {
    visibility: visible;
  }
  .v-d-hidden-lp {
    visibility: hidden;
  }
  .v-ta-left-lp {
    text-align: left;
  }
  .v-ta-center-lp {
    text-align: center;
  }
  .v-ta-right-lp {
    text-align: right;
  }
  .v-ai-flex-start-lp {
    align-items: flex-start;
  }
  .v-jc-flex-start-lp {
    justify-content: flex-start;
  }
  .v-as-flex-start-lp {
    align-self: flex-start;
  }
  .v-ai-flex-end-lp {
    align-items: flex-end;
  }
  .v-jc-flex-end-lp {
    justify-content: flex-end;
  }
  .v-as-flex-end-lp {
    align-self: flex-end;
  }
  .v-ai-center-lp {
    align-items: center;
  }
  .v-jc-center-lp {
    justify-content: center;
  }
  .v-as-center-lp {
    align-self: center;
  }
  .v-jc-space-between-lp {
    justify-content: space-between;
  }
  .v-jc-space-around-lp {
    justify-content: space-around;
  }
  .v-flexdir-row-lp {
    flex-direction: row;
  }
  .v-flexdir-row-reverse-lp {
    flex-direction: row-reverse;
  }
  .v-flexdir-column-lp {
    flex-direction: column;
  }
  .v-flexdir-column-reverse-lp {
    flex-direction: column-reverse;
  }
  .v-pt-0-lp {
    padding-top: 0px;
  }
  .v-pb-0-lp {
    padding-bottom: 0px;
  }
  .v-pr-0-lp {
    padding-right: 0px;
  }
  .v-pl-0-lp {
    padding-left: 0px;
  }
  .v-mt-0-lp {
    margin-top: 0px;
  }
  .v-mb-0-lp {
    margin-bottom: 0px;
  }
  .v-ml-0-lp {
    margin-left: 0px;
  }
  .v-mr-0-lp {
    margin-right: 0px;
  }
  .v-pt-4-lp {
    padding-top: 4px;
  }
  .v-pb-4-lp {
    padding-bottom: 4px;
  }
  .v-pr-4-lp {
    padding-right: 4px;
  }
  .v-pl-4-lp {
    padding-left: 4px;
  }
  .v-mt-4-lp {
    margin-top: 4px;
  }
  .v-mb-4-lp {
    margin-bottom: 4px;
  }
  .v-ml-4-lp {
    margin-left: 4px;
  }
  .v-mr-4-lp {
    margin-right: 4px;
  }
  .v-pt-5-lp {
    padding-top: 5px;
  }
  .v-pb-5-lp {
    padding-bottom: 5px;
  }
  .v-pr-5-lp {
    padding-right: 5px;
  }
  .v-pl-5-lp {
    padding-left: 5px;
  }
  .v-mt-5-lp {
    margin-top: 5px;
  }
  .v-mb-5-lp {
    margin-bottom: 5px;
  }
  .v-ml-5-lp {
    margin-left: 5px;
  }
  .v-mr-5-lp {
    margin-right: 5px;
  }
  .v-pt-8-lp {
    padding-top: 8px;
  }
  .v-pb-8-lp {
    padding-bottom: 8px;
  }
  .v-pr-8-lp {
    padding-right: 8px;
  }
  .v-pl-8-lp {
    padding-left: 8px;
  }
  .v-mt-8-lp {
    margin-top: 8px;
  }
  .v-mb-8-lp {
    margin-bottom: 8px;
  }
  .v-ml-8-lp {
    margin-left: 8px;
  }
  .v-mr-8-lp {
    margin-right: 8px;
  }
  .v-pt-10-lp {
    padding-top: 10px;
  }
  .v-pb-10-lp {
    padding-bottom: 10px;
  }
  .v-pr-10-lp {
    padding-right: 10px;
  }
  .v-pl-10-lp {
    padding-left: 10px;
  }
  .v-mt-10-lp {
    margin-top: 10px;
  }
  .v-mb-10-lp {
    margin-bottom: 10px;
  }
  .v-ml-10-lp {
    margin-left: 10px;
  }
  .v-mr-10-lp {
    margin-right: 10px;
  }
  .v-pt-12-lp {
    padding-top: 12px;
  }
  .v-pb-12-lp {
    padding-bottom: 12px;
  }
  .v-pr-12-lp {
    padding-right: 12px;
  }
  .v-pl-12-lp {
    padding-left: 12px;
  }
  .v-mt-12-lp {
    margin-top: 12px;
  }
  .v-mb-12-lp {
    margin-bottom: 12px;
  }
  .v-ml-12-lp {
    margin-left: 12px;
  }
  .v-mr-12-lp {
    margin-right: 12px;
  }
  .v-pt-14-lp {
    padding-top: 14px;
  }
  .v-pb-14-lp {
    padding-bottom: 14px;
  }
  .v-pr-14-lp {
    padding-right: 14px;
  }
  .v-pl-14-lp {
    padding-left: 14px;
  }
  .v-mt-14-lp {
    margin-top: 14px;
  }
  .v-mb-14-lp {
    margin-bottom: 14px;
  }
  .v-ml-14-lp {
    margin-left: 14px;
  }
  .v-mr-14-lp {
    margin-right: 14px;
  }
  .v-pt-16-lp {
    padding-top: 16px;
  }
  .v-pb-16-lp {
    padding-bottom: 16px;
  }
  .v-pr-16-lp {
    padding-right: 16px;
  }
  .v-pl-16-lp {
    padding-left: 16px;
  }
  .v-mt-16-lp {
    margin-top: 16px;
  }
  .v-mb-16-lp {
    margin-bottom: 16px;
  }
  .v-ml-16-lp {
    margin-left: 16px;
  }
  .v-mr-16-lp {
    margin-right: 16px;
  }
  .v-pt-24-lp {
    padding-top: 24px;
  }
  .v-pb-24-lp {
    padding-bottom: 24px;
  }
  .v-pr-24-lp {
    padding-right: 24px;
  }
  .v-pl-24-lp {
    padding-left: 24px;
  }
  .v-mt-24-lp {
    margin-top: 24px;
  }
  .v-mb-24-lp {
    margin-bottom: 24px;
  }
  .v-ml-24-lp {
    margin-left: 24px;
  }
  .v-mr-24-lp {
    margin-right: 24px;
  }
  .v-pt-32-lp {
    padding-top: 32px;
  }
  .v-pb-32-lp {
    padding-bottom: 32px;
  }
  .v-pr-32-lp {
    padding-right: 32px;
  }
  .v-pl-32-lp {
    padding-left: 32px;
  }
  .v-mt-32-lp {
    margin-top: 32px;
  }
  .v-mb-32-lp {
    margin-bottom: 32px;
  }
  .v-ml-32-lp {
    margin-left: 32px;
  }
  .v-mr-32-lp {
    margin-right: 32px;
  }
  .v-pt-44-lp {
    padding-top: 44px;
  }
  .v-pb-44-lp {
    padding-bottom: 44px;
  }
  .v-pr-44-lp {
    padding-right: 44px;
  }
  .v-pl-44-lp {
    padding-left: 44px;
  }
  .v-mt-44-lp {
    margin-top: 44px;
  }
  .v-mb-44-lp {
    margin-bottom: 44px;
  }
  .v-ml-44-lp {
    margin-left: 44px;
  }
  .v-mr-44-lp {
    margin-right: 44px;
  }
  .v-pt-48-lp {
    padding-top: 48px;
  }
  .v-pb-48-lp {
    padding-bottom: 48px;
  }
  .v-pr-48-lp {
    padding-right: 48px;
  }
  .v-pl-48-lp {
    padding-left: 48px;
  }
  .v-mt-48-lp {
    margin-top: 48px;
  }
  .v-mb-48-lp {
    margin-bottom: 48px;
  }
  .v-ml-48-lp {
    margin-left: 48px;
  }
  .v-mr-48-lp {
    margin-right: 48px;
  }
  .v-pt-64-lp {
    padding-top: 64px;
  }
  .v-pb-64-lp {
    padding-bottom: 64px;
  }
  .v-pr-64-lp {
    padding-right: 64px;
  }
  .v-pl-64-lp {
    padding-left: 64px;
  }
  .v-mt-64-lp {
    margin-top: 64px;
  }
  .v-mb-64-lp {
    margin-bottom: 64px;
  }
  .v-ml-64-lp {
    margin-left: 64px;
  }
  .v-mr-64-lp {
    margin-right: 64px;
  }
  .v-pt-82-lp {
    padding-top: 82px;
  }
  .v-pb-82-lp {
    padding-bottom: 82px;
  }
  .v-pr-82-lp {
    padding-right: 82px;
  }
  .v-pl-82-lp {
    padding-left: 82px;
  }
  .v-mt-82-lp {
    margin-top: 82px;
  }
  .v-mb-82-lp {
    margin-bottom: 82px;
  }
  .v-ml-82-lp {
    margin-left: 82px;
  }
  .v-mr-82-lp {
    margin-right: 82px;
  }
  .v-pt-96-lp {
    padding-top: 96px;
  }
  .v-pb-96-lp {
    padding-bottom: 96px;
  }
  .v-pr-96-lp {
    padding-right: 96px;
  }
  .v-pl-96-lp {
    padding-left: 96px;
  }
  .v-mt-96-lp {
    margin-top: 96px;
  }
  .v-mb-96-lp {
    margin-bottom: 96px;
  }
  .v-ml-96-lp {
    margin-left: 96px;
  }
  .v-mr-96-lp {
    margin-right: 96px;
  }
  .v-mx-auto-lp {
    margin-left: auto;
    margin-right: auto;
  }
  .v-mx-auto-left-lp {
    margin-left: auto;
    margin-right: initial;
  }
  .v-mx-auto-right-lp {
    margin-left: initial;
    margin-right: left;
  }
  .v-mx-none-lp {
    margin-left: initial;
    margin-right: initial;
  }
  .v-mx-0-lp {
    margin-left: 0;
    margin-right: 0;
  }
  .v-px-0-lp {
    padding-left: 0;
    padding-right: 0;
  }
  .v-w-100-lp {
    width: 100%;
  }
  .v-w-auto-lp {
    width: auto;
    max-width: none;
  }
  .v-h-56-lp {
    height: 56px;
  }
  .v-h-100-lp {
    height: 100%;
  }
  .v-h-auto-lp {
    height: auto;
  }
  .v-ws-nowrap-lp {
    white-space: nowrap;
  }
  .v-ws-wrap-lp {
    white-space: normal;
  }
  .v-fz-12-lp {
    font-size: 12px;
  }
  .v-fz-16-lp {
    font-size: 16px;
  }
  .v-fz-24-lp {
    font-size: 24px;
  }
  .v-fz-38-lp {
    font-size: 38px;
  }
  .v-fz-48-lp {
    font-size: 48px;
  }
  .v-pos-relative-lp {
    position: relative;
  }
  .v-pos-absolute-lp {
    position: absolute;
  }
  .v-pos-fixed-lp {
    position: fixed;
  }
  .v-pos-initial-lp {
    position: initial;
  }
  .v-zi-0-lp {
    z-index: 0;
  }
  .v-zi-5-lp {
    z-index: 5;
  }
  .v-zi-10-lp {
    z-index: 10;
  }
  .v-zi-15-lp {
    z-index: 15;
  }
  .v-zi-20-lp {
    z-index: 20;
  }
  .v-brad-1000-lp {
    border-radius: 1000px;
  }
  .v-brad-100-lp {
    border-radius: 100px;
  }
  .v-brad-24-lp {
    border-radius: 24px;
  }
  .v-brad-16-lp {
    border-radius: 16px;
  }
  .v-brad-12-lp {
    border-radius: 12px;
  }
  .v-brad-8-lp {
    border-radius: 8px;
  }
  .v-brad-4-lp {
    border-radius: 4px;
  }
  .v-icon-16-lp {
    width: 16px;
    height: 16px;
  }
  .v-icon-20-lp {
    width: 20px;
    height: 20px;
  }
  .v-icon-24-lp {
    width: 24px;
    height: 24px;
  }
  .v-icon-40-lp {
    width: 40px;
    height: 40px;
  }
}
@media all and (max-width: 1199px) {
  .v-d-flex-tb {
    display: flex;
    flex-wrap: wrap;
  }
  .v-d-inline-flex-tb {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .v-flex-nowrap-tb {
    flex-wrap: nowrap;
  }
  .v-flex-wrap-tb {
    flex-wrap: wrap;
  }
  .v-flexgrow-1-tb {
    flex-grow: 1;
  }
  .v-d-block-tb {
    display: block;
  }
  .v-d-inline-block-tb {
    display: inline-block;
  }
  .v-d-inline-tb {
    display: inline;
  }
  .v-d-none-tb {
    display: none;
  }
  .v-d-visible-tb {
    visibility: visible;
  }
  .v-d-hidden-tb {
    visibility: hidden;
  }
  .v-ta-left-tb {
    text-align: left;
  }
  .v-ta-center-tb {
    text-align: center;
  }
  .v-ta-right-tb {
    text-align: right;
  }
  .v-ai-flex-start-tb {
    align-items: flex-start;
  }
  .v-jc-flex-start-tb {
    justify-content: flex-start;
  }
  .v-as-flex-start-tb {
    align-self: flex-start;
  }
  .v-ai-flex-end-tb {
    align-items: flex-end;
  }
  .v-jc-flex-end-tb {
    justify-content: flex-end;
  }
  .v-as-flex-end-tb {
    align-self: flex-end;
  }
  .v-ai-center-tb {
    align-items: center;
  }
  .v-jc-center-tb {
    justify-content: center;
  }
  .v-as-center-tb {
    align-self: center;
  }
  .v-jc-space-between-tb {
    justify-content: space-between;
  }
  .v-jc-space-around-tb {
    justify-content: space-around;
  }
  .v-flexdir-row-tb {
    flex-direction: row;
  }
  .v-flexdir-row-reverse-tb {
    flex-direction: row-reverse;
  }
  .v-flexdir-column-tb {
    flex-direction: column;
  }
  .v-flexdir-column-reverse-tb {
    flex-direction: column-reverse;
  }
  .v-pt-0-tb {
    padding-top: 0px;
  }
  .v-pb-0-tb {
    padding-bottom: 0px;
  }
  .v-pr-0-tb {
    padding-right: 0px;
  }
  .v-pl-0-tb {
    padding-left: 0px;
  }
  .v-mt-0-tb {
    margin-top: 0px;
  }
  .v-mb-0-tb {
    margin-bottom: 0px;
  }
  .v-ml-0-tb {
    margin-left: 0px;
  }
  .v-mr-0-tb {
    margin-right: 0px;
  }
  .v-pt-4-tb {
    padding-top: 4px;
  }
  .v-pb-4-tb {
    padding-bottom: 4px;
  }
  .v-pr-4-tb {
    padding-right: 4px;
  }
  .v-pl-4-tb {
    padding-left: 4px;
  }
  .v-mt-4-tb {
    margin-top: 4px;
  }
  .v-mb-4-tb {
    margin-bottom: 4px;
  }
  .v-ml-4-tb {
    margin-left: 4px;
  }
  .v-mr-4-tb {
    margin-right: 4px;
  }
  .v-pt-5-tb {
    padding-top: 5px;
  }
  .v-pb-5-tb {
    padding-bottom: 5px;
  }
  .v-pr-5-tb {
    padding-right: 5px;
  }
  .v-pl-5-tb {
    padding-left: 5px;
  }
  .v-mt-5-tb {
    margin-top: 5px;
  }
  .v-mb-5-tb {
    margin-bottom: 5px;
  }
  .v-ml-5-tb {
    margin-left: 5px;
  }
  .v-mr-5-tb {
    margin-right: 5px;
  }
  .v-pt-8-tb {
    padding-top: 8px;
  }
  .v-pb-8-tb {
    padding-bottom: 8px;
  }
  .v-pr-8-tb {
    padding-right: 8px;
  }
  .v-pl-8-tb {
    padding-left: 8px;
  }
  .v-mt-8-tb {
    margin-top: 8px;
  }
  .v-mb-8-tb {
    margin-bottom: 8px;
  }
  .v-ml-8-tb {
    margin-left: 8px;
  }
  .v-mr-8-tb {
    margin-right: 8px;
  }
  .v-pt-10-tb {
    padding-top: 10px;
  }
  .v-pb-10-tb {
    padding-bottom: 10px;
  }
  .v-pr-10-tb {
    padding-right: 10px;
  }
  .v-pl-10-tb {
    padding-left: 10px;
  }
  .v-mt-10-tb {
    margin-top: 10px;
  }
  .v-mb-10-tb {
    margin-bottom: 10px;
  }
  .v-ml-10-tb {
    margin-left: 10px;
  }
  .v-mr-10-tb {
    margin-right: 10px;
  }
  .v-pt-12-tb {
    padding-top: 12px;
  }
  .v-pb-12-tb {
    padding-bottom: 12px;
  }
  .v-pr-12-tb {
    padding-right: 12px;
  }
  .v-pl-12-tb {
    padding-left: 12px;
  }
  .v-mt-12-tb {
    margin-top: 12px;
  }
  .v-mb-12-tb {
    margin-bottom: 12px;
  }
  .v-ml-12-tb {
    margin-left: 12px;
  }
  .v-mr-12-tb {
    margin-right: 12px;
  }
  .v-pt-14-tb {
    padding-top: 14px;
  }
  .v-pb-14-tb {
    padding-bottom: 14px;
  }
  .v-pr-14-tb {
    padding-right: 14px;
  }
  .v-pl-14-tb {
    padding-left: 14px;
  }
  .v-mt-14-tb {
    margin-top: 14px;
  }
  .v-mb-14-tb {
    margin-bottom: 14px;
  }
  .v-ml-14-tb {
    margin-left: 14px;
  }
  .v-mr-14-tb {
    margin-right: 14px;
  }
  .v-pt-16-tb {
    padding-top: 16px;
  }
  .v-pb-16-tb {
    padding-bottom: 16px;
  }
  .v-pr-16-tb {
    padding-right: 16px;
  }
  .v-pl-16-tb {
    padding-left: 16px;
  }
  .v-mt-16-tb {
    margin-top: 16px;
  }
  .v-mb-16-tb {
    margin-bottom: 16px;
  }
  .v-ml-16-tb {
    margin-left: 16px;
  }
  .v-mr-16-tb {
    margin-right: 16px;
  }
  .v-pt-24-tb {
    padding-top: 24px;
  }
  .v-pb-24-tb {
    padding-bottom: 24px;
  }
  .v-pr-24-tb {
    padding-right: 24px;
  }
  .v-pl-24-tb {
    padding-left: 24px;
  }
  .v-mt-24-tb {
    margin-top: 24px;
  }
  .v-mb-24-tb {
    margin-bottom: 24px;
  }
  .v-ml-24-tb {
    margin-left: 24px;
  }
  .v-mr-24-tb {
    margin-right: 24px;
  }
  .v-pt-32-tb {
    padding-top: 32px;
  }
  .v-pb-32-tb {
    padding-bottom: 32px;
  }
  .v-pr-32-tb {
    padding-right: 32px;
  }
  .v-pl-32-tb {
    padding-left: 32px;
  }
  .v-mt-32-tb {
    margin-top: 32px;
  }
  .v-mb-32-tb {
    margin-bottom: 32px;
  }
  .v-ml-32-tb {
    margin-left: 32px;
  }
  .v-mr-32-tb {
    margin-right: 32px;
  }
  .v-pt-44-tb {
    padding-top: 44px;
  }
  .v-pb-44-tb {
    padding-bottom: 44px;
  }
  .v-pr-44-tb {
    padding-right: 44px;
  }
  .v-pl-44-tb {
    padding-left: 44px;
  }
  .v-mt-44-tb {
    margin-top: 44px;
  }
  .v-mb-44-tb {
    margin-bottom: 44px;
  }
  .v-ml-44-tb {
    margin-left: 44px;
  }
  .v-mr-44-tb {
    margin-right: 44px;
  }
  .v-pt-48-tb {
    padding-top: 48px;
  }
  .v-pb-48-tb {
    padding-bottom: 48px;
  }
  .v-pr-48-tb {
    padding-right: 48px;
  }
  .v-pl-48-tb {
    padding-left: 48px;
  }
  .v-mt-48-tb {
    margin-top: 48px;
  }
  .v-mb-48-tb {
    margin-bottom: 48px;
  }
  .v-ml-48-tb {
    margin-left: 48px;
  }
  .v-mr-48-tb {
    margin-right: 48px;
  }
  .v-pt-64-tb {
    padding-top: 64px;
  }
  .v-pb-64-tb {
    padding-bottom: 64px;
  }
  .v-pr-64-tb {
    padding-right: 64px;
  }
  .v-pl-64-tb {
    padding-left: 64px;
  }
  .v-mt-64-tb {
    margin-top: 64px;
  }
  .v-mb-64-tb {
    margin-bottom: 64px;
  }
  .v-ml-64-tb {
    margin-left: 64px;
  }
  .v-mr-64-tb {
    margin-right: 64px;
  }
  .v-pt-82-tb {
    padding-top: 82px;
  }
  .v-pb-82-tb {
    padding-bottom: 82px;
  }
  .v-pr-82-tb {
    padding-right: 82px;
  }
  .v-pl-82-tb {
    padding-left: 82px;
  }
  .v-mt-82-tb {
    margin-top: 82px;
  }
  .v-mb-82-tb {
    margin-bottom: 82px;
  }
  .v-ml-82-tb {
    margin-left: 82px;
  }
  .v-mr-82-tb {
    margin-right: 82px;
  }
  .v-pt-96-tb {
    padding-top: 96px;
  }
  .v-pb-96-tb {
    padding-bottom: 96px;
  }
  .v-pr-96-tb {
    padding-right: 96px;
  }
  .v-pl-96-tb {
    padding-left: 96px;
  }
  .v-mt-96-tb {
    margin-top: 96px;
  }
  .v-mb-96-tb {
    margin-bottom: 96px;
  }
  .v-ml-96-tb {
    margin-left: 96px;
  }
  .v-mr-96-tb {
    margin-right: 96px;
  }
  .v-mx-auto-tb {
    margin-left: auto;
    margin-right: auto;
  }
  .v-mx-auto-left-tb {
    margin-left: auto;
    margin-right: initial;
  }
  .v-mx-auto-right-tb {
    margin-left: initial;
    margin-right: left;
  }
  .v-mx-none-tb {
    margin-left: initial;
    margin-right: initial;
  }
  .v-mx-0-tb {
    margin-left: 0;
    margin-right: 0;
  }
  .v-px-0-tb {
    padding-left: 0;
    padding-right: 0;
  }
  .v-w-100-tb {
    width: 100%;
  }
  .v-w-auto-tb {
    width: auto;
    max-width: none;
  }
  .v-h-56-tb {
    height: 56px;
  }
  .v-h-100-tb {
    height: 100%;
  }
  .v-h-auto-tb {
    height: auto;
  }
  .v-ws-nowrap-tb {
    white-space: nowrap;
  }
  .v-ws-wrap-tb {
    white-space: normal;
  }
  .v-fz-12-tb {
    font-size: 12px;
  }
  .v-fz-16-tb {
    font-size: 16px;
  }
  .v-fz-24-tb {
    font-size: 24px;
  }
  .v-fz-38-tb {
    font-size: 38px;
  }
  .v-fz-48-tb {
    font-size: 48px;
  }
  .v-pos-relative-tb {
    position: relative;
  }
  .v-pos-absolute-tb {
    position: absolute;
  }
  .v-pos-fixed-tb {
    position: fixed;
  }
  .v-pos-initial-tb {
    position: initial;
  }
  .v-zi-0-tb {
    z-index: 0;
  }
  .v-zi-5-tb {
    z-index: 5;
  }
  .v-zi-10-tb {
    z-index: 10;
  }
  .v-zi-15-tb {
    z-index: 15;
  }
  .v-zi-20-tb {
    z-index: 20;
  }
  .v-brad-1000-tb {
    border-radius: 1000px;
  }
  .v-brad-100-tb {
    border-radius: 100px;
  }
  .v-brad-24-tb {
    border-radius: 24px;
  }
  .v-brad-16-tb {
    border-radius: 16px;
  }
  .v-brad-12-tb {
    border-radius: 12px;
  }
  .v-brad-8-tb {
    border-radius: 8px;
  }
  .v-brad-4-tb {
    border-radius: 4px;
  }
  .v-icon-16-tb {
    width: 16px;
    height: 16px;
  }
  .v-icon-20-tb {
    width: 20px;
    height: 20px;
  }
  .v-icon-24-tb {
    width: 24px;
    height: 24px;
  }
  .v-icon-40-tb {
    width: 40px;
    height: 40px;
  }
}
@media all and (max-width: 1023px) {
  .v-d-flex-pd {
    display: flex;
    flex-wrap: wrap;
  }
  .v-d-inline-flex-pd {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .v-flex-nowrap-pd {
    flex-wrap: nowrap;
  }
  .v-flex-wrap-pd {
    flex-wrap: wrap;
  }
  .v-flexgrow-1-pd {
    flex-grow: 1;
  }
  .v-d-block-pd {
    display: block;
  }
  .v-d-inline-block-pd {
    display: inline-block;
  }
  .v-d-inline-pd {
    display: inline;
  }
  .v-d-none-pd {
    display: none;
  }
  .v-d-visible-pd {
    visibility: visible;
  }
  .v-d-hidden-pd {
    visibility: hidden;
  }
  .v-ta-left-pd {
    text-align: left;
  }
  .v-ta-center-pd {
    text-align: center;
  }
  .v-ta-right-pd {
    text-align: right;
  }
  .v-ai-flex-start-pd {
    align-items: flex-start;
  }
  .v-jc-flex-start-pd {
    justify-content: flex-start;
  }
  .v-as-flex-start-pd {
    align-self: flex-start;
  }
  .v-ai-flex-end-pd {
    align-items: flex-end;
  }
  .v-jc-flex-end-pd {
    justify-content: flex-end;
  }
  .v-as-flex-end-pd {
    align-self: flex-end;
  }
  .v-ai-center-pd {
    align-items: center;
  }
  .v-jc-center-pd {
    justify-content: center;
  }
  .v-as-center-pd {
    align-self: center;
  }
  .v-jc-space-between-pd {
    justify-content: space-between;
  }
  .v-jc-space-around-pd {
    justify-content: space-around;
  }
  .v-flexdir-row-pd {
    flex-direction: row;
  }
  .v-flexdir-row-reverse-pd {
    flex-direction: row-reverse;
  }
  .v-flexdir-column-pd {
    flex-direction: column;
  }
  .v-flexdir-column-reverse-pd {
    flex-direction: column-reverse;
  }
  .v-pt-0-pd {
    padding-top: 0px;
  }
  .v-pb-0-pd {
    padding-bottom: 0px;
  }
  .v-pr-0-pd {
    padding-right: 0px;
  }
  .v-pl-0-pd {
    padding-left: 0px;
  }
  .v-mt-0-pd {
    margin-top: 0px;
  }
  .v-mb-0-pd {
    margin-bottom: 0px;
  }
  .v-ml-0-pd {
    margin-left: 0px;
  }
  .v-mr-0-pd {
    margin-right: 0px;
  }
  .v-pt-4-pd {
    padding-top: 4px;
  }
  .v-pb-4-pd {
    padding-bottom: 4px;
  }
  .v-pr-4-pd {
    padding-right: 4px;
  }
  .v-pl-4-pd {
    padding-left: 4px;
  }
  .v-mt-4-pd {
    margin-top: 4px;
  }
  .v-mb-4-pd {
    margin-bottom: 4px;
  }
  .v-ml-4-pd {
    margin-left: 4px;
  }
  .v-mr-4-pd {
    margin-right: 4px;
  }
  .v-pt-5-pd {
    padding-top: 5px;
  }
  .v-pb-5-pd {
    padding-bottom: 5px;
  }
  .v-pr-5-pd {
    padding-right: 5px;
  }
  .v-pl-5-pd {
    padding-left: 5px;
  }
  .v-mt-5-pd {
    margin-top: 5px;
  }
  .v-mb-5-pd {
    margin-bottom: 5px;
  }
  .v-ml-5-pd {
    margin-left: 5px;
  }
  .v-mr-5-pd {
    margin-right: 5px;
  }
  .v-pt-8-pd {
    padding-top: 8px;
  }
  .v-pb-8-pd {
    padding-bottom: 8px;
  }
  .v-pr-8-pd {
    padding-right: 8px;
  }
  .v-pl-8-pd {
    padding-left: 8px;
  }
  .v-mt-8-pd {
    margin-top: 8px;
  }
  .v-mb-8-pd {
    margin-bottom: 8px;
  }
  .v-ml-8-pd {
    margin-left: 8px;
  }
  .v-mr-8-pd {
    margin-right: 8px;
  }
  .v-pt-10-pd {
    padding-top: 10px;
  }
  .v-pb-10-pd {
    padding-bottom: 10px;
  }
  .v-pr-10-pd {
    padding-right: 10px;
  }
  .v-pl-10-pd {
    padding-left: 10px;
  }
  .v-mt-10-pd {
    margin-top: 10px;
  }
  .v-mb-10-pd {
    margin-bottom: 10px;
  }
  .v-ml-10-pd {
    margin-left: 10px;
  }
  .v-mr-10-pd {
    margin-right: 10px;
  }
  .v-pt-12-pd {
    padding-top: 12px;
  }
  .v-pb-12-pd {
    padding-bottom: 12px;
  }
  .v-pr-12-pd {
    padding-right: 12px;
  }
  .v-pl-12-pd {
    padding-left: 12px;
  }
  .v-mt-12-pd {
    margin-top: 12px;
  }
  .v-mb-12-pd {
    margin-bottom: 12px;
  }
  .v-ml-12-pd {
    margin-left: 12px;
  }
  .v-mr-12-pd {
    margin-right: 12px;
  }
  .v-pt-14-pd {
    padding-top: 14px;
  }
  .v-pb-14-pd {
    padding-bottom: 14px;
  }
  .v-pr-14-pd {
    padding-right: 14px;
  }
  .v-pl-14-pd {
    padding-left: 14px;
  }
  .v-mt-14-pd {
    margin-top: 14px;
  }
  .v-mb-14-pd {
    margin-bottom: 14px;
  }
  .v-ml-14-pd {
    margin-left: 14px;
  }
  .v-mr-14-pd {
    margin-right: 14px;
  }
  .v-pt-16-pd {
    padding-top: 16px;
  }
  .v-pb-16-pd {
    padding-bottom: 16px;
  }
  .v-pr-16-pd {
    padding-right: 16px;
  }
  .v-pl-16-pd {
    padding-left: 16px;
  }
  .v-mt-16-pd {
    margin-top: 16px;
  }
  .v-mb-16-pd {
    margin-bottom: 16px;
  }
  .v-ml-16-pd {
    margin-left: 16px;
  }
  .v-mr-16-pd {
    margin-right: 16px;
  }
  .v-pt-24-pd {
    padding-top: 24px;
  }
  .v-pb-24-pd {
    padding-bottom: 24px;
  }
  .v-pr-24-pd {
    padding-right: 24px;
  }
  .v-pl-24-pd {
    padding-left: 24px;
  }
  .v-mt-24-pd {
    margin-top: 24px;
  }
  .v-mb-24-pd {
    margin-bottom: 24px;
  }
  .v-ml-24-pd {
    margin-left: 24px;
  }
  .v-mr-24-pd {
    margin-right: 24px;
  }
  .v-pt-32-pd {
    padding-top: 32px;
  }
  .v-pb-32-pd {
    padding-bottom: 32px;
  }
  .v-pr-32-pd {
    padding-right: 32px;
  }
  .v-pl-32-pd {
    padding-left: 32px;
  }
  .v-mt-32-pd {
    margin-top: 32px;
  }
  .v-mb-32-pd {
    margin-bottom: 32px;
  }
  .v-ml-32-pd {
    margin-left: 32px;
  }
  .v-mr-32-pd {
    margin-right: 32px;
  }
  .v-pt-44-pd {
    padding-top: 44px;
  }
  .v-pb-44-pd {
    padding-bottom: 44px;
  }
  .v-pr-44-pd {
    padding-right: 44px;
  }
  .v-pl-44-pd {
    padding-left: 44px;
  }
  .v-mt-44-pd {
    margin-top: 44px;
  }
  .v-mb-44-pd {
    margin-bottom: 44px;
  }
  .v-ml-44-pd {
    margin-left: 44px;
  }
  .v-mr-44-pd {
    margin-right: 44px;
  }
  .v-pt-48-pd {
    padding-top: 48px;
  }
  .v-pb-48-pd {
    padding-bottom: 48px;
  }
  .v-pr-48-pd {
    padding-right: 48px;
  }
  .v-pl-48-pd {
    padding-left: 48px;
  }
  .v-mt-48-pd {
    margin-top: 48px;
  }
  .v-mb-48-pd {
    margin-bottom: 48px;
  }
  .v-ml-48-pd {
    margin-left: 48px;
  }
  .v-mr-48-pd {
    margin-right: 48px;
  }
  .v-pt-64-pd {
    padding-top: 64px;
  }
  .v-pb-64-pd {
    padding-bottom: 64px;
  }
  .v-pr-64-pd {
    padding-right: 64px;
  }
  .v-pl-64-pd {
    padding-left: 64px;
  }
  .v-mt-64-pd {
    margin-top: 64px;
  }
  .v-mb-64-pd {
    margin-bottom: 64px;
  }
  .v-ml-64-pd {
    margin-left: 64px;
  }
  .v-mr-64-pd {
    margin-right: 64px;
  }
  .v-pt-82-pd {
    padding-top: 82px;
  }
  .v-pb-82-pd {
    padding-bottom: 82px;
  }
  .v-pr-82-pd {
    padding-right: 82px;
  }
  .v-pl-82-pd {
    padding-left: 82px;
  }
  .v-mt-82-pd {
    margin-top: 82px;
  }
  .v-mb-82-pd {
    margin-bottom: 82px;
  }
  .v-ml-82-pd {
    margin-left: 82px;
  }
  .v-mr-82-pd {
    margin-right: 82px;
  }
  .v-pt-96-pd {
    padding-top: 96px;
  }
  .v-pb-96-pd {
    padding-bottom: 96px;
  }
  .v-pr-96-pd {
    padding-right: 96px;
  }
  .v-pl-96-pd {
    padding-left: 96px;
  }
  .v-mt-96-pd {
    margin-top: 96px;
  }
  .v-mb-96-pd {
    margin-bottom: 96px;
  }
  .v-ml-96-pd {
    margin-left: 96px;
  }
  .v-mr-96-pd {
    margin-right: 96px;
  }
  .v-mx-auto-pd {
    margin-left: auto;
    margin-right: auto;
  }
  .v-mx-auto-left-pd {
    margin-left: auto;
    margin-right: initial;
  }
  .v-mx-auto-right-pd {
    margin-left: initial;
    margin-right: left;
  }
  .v-mx-none-pd {
    margin-left: initial;
    margin-right: initial;
  }
  .v-mx-0-pd {
    margin-left: 0;
    margin-right: 0;
  }
  .v-px-0-pd {
    padding-left: 0;
    padding-right: 0;
  }
  .v-w-100-pd {
    width: 100%;
  }
  .v-w-auto-pd {
    width: auto;
    max-width: none;
  }
  .v-h-56-pd {
    height: 56px;
  }
  .v-h-100-pd {
    height: 100%;
  }
  .v-h-auto-pd {
    height: auto;
  }
  .v-ws-nowrap-pd {
    white-space: nowrap;
  }
  .v-ws-wrap-pd {
    white-space: normal;
  }
  .v-fz-12-pd {
    font-size: 12px;
  }
  .v-fz-16-pd {
    font-size: 16px;
  }
  .v-fz-24-pd {
    font-size: 24px;
  }
  .v-fz-38-pd {
    font-size: 38px;
  }
  .v-fz-48-pd {
    font-size: 48px;
  }
  .v-pos-relative-pd {
    position: relative;
  }
  .v-pos-absolute-pd {
    position: absolute;
  }
  .v-pos-fixed-pd {
    position: fixed;
  }
  .v-pos-initial-pd {
    position: initial;
  }
  .v-zi-0-pd {
    z-index: 0;
  }
  .v-zi-5-pd {
    z-index: 5;
  }
  .v-zi-10-pd {
    z-index: 10;
  }
  .v-zi-15-pd {
    z-index: 15;
  }
  .v-zi-20-pd {
    z-index: 20;
  }
  .v-brad-1000-pd {
    border-radius: 1000px;
  }
  .v-brad-100-pd {
    border-radius: 100px;
  }
  .v-brad-24-pd {
    border-radius: 24px;
  }
  .v-brad-16-pd {
    border-radius: 16px;
  }
  .v-brad-12-pd {
    border-radius: 12px;
  }
  .v-brad-8-pd {
    border-radius: 8px;
  }
  .v-brad-4-pd {
    border-radius: 4px;
  }
  .v-icon-16-pd {
    width: 16px;
    height: 16px;
  }
  .v-icon-20-pd {
    width: 20px;
    height: 20px;
  }
  .v-icon-24-pd {
    width: 24px;
    height: 24px;
  }
  .v-icon-40-pd {
    width: 40px;
    height: 40px;
  }
}
@media all and (max-width: 767px) {
  .v-d-flex-ph {
    display: flex;
    flex-wrap: wrap;
  }
  .v-d-inline-flex-ph {
    display: inline-flex;
    flex-wrap: wrap;
  }
  .v-flex-nowrap-ph {
    flex-wrap: nowrap;
  }
  .v-flex-wrap-ph {
    flex-wrap: wrap;
  }
  .v-flexgrow-1-ph {
    flex-grow: 1;
  }
  .v-d-block-ph {
    display: block;
  }
  .v-d-inline-block-ph {
    display: inline-block;
  }
  .v-d-inline-ph {
    display: inline;
  }
  .v-d-none-ph {
    display: none;
  }
  .v-d-visible-ph {
    visibility: visible;
  }
  .v-d-hidden-ph {
    visibility: hidden;
  }
  .v-ta-left-ph {
    text-align: left;
  }
  .v-ta-center-ph {
    text-align: center;
  }
  .v-ta-right-ph {
    text-align: right;
  }
  .v-ai-flex-start-ph {
    align-items: flex-start;
  }
  .v-jc-flex-start-ph {
    justify-content: flex-start;
  }
  .v-as-flex-start-ph {
    align-self: flex-start;
  }
  .v-ai-flex-end-ph {
    align-items: flex-end;
  }
  .v-jc-flex-end-ph {
    justify-content: flex-end;
  }
  .v-as-flex-end-ph {
    align-self: flex-end;
  }
  .v-ai-center-ph {
    align-items: center;
  }
  .v-jc-center-ph {
    justify-content: center;
  }
  .v-as-center-ph {
    align-self: center;
  }
  .v-jc-space-between-ph {
    justify-content: space-between;
  }
  .v-jc-space-around-ph {
    justify-content: space-around;
  }
  .v-flexdir-row-ph {
    flex-direction: row;
  }
  .v-flexdir-row-reverse-ph {
    flex-direction: row-reverse;
  }
  .v-flexdir-column-ph {
    flex-direction: column;
  }
  .v-flexdir-column-reverse-ph {
    flex-direction: column-reverse;
  }
  .v-pt-0-ph {
    padding-top: 0px;
  }
  .v-pb-0-ph {
    padding-bottom: 0px;
  }
  .v-pr-0-ph {
    padding-right: 0px;
  }
  .v-pl-0-ph {
    padding-left: 0px;
  }
  .v-mt-0-ph {
    margin-top: 0px;
  }
  .v-mb-0-ph {
    margin-bottom: 0px;
  }
  .v-ml-0-ph {
    margin-left: 0px;
  }
  .v-mr-0-ph {
    margin-right: 0px;
  }
  .v-pt-4-ph {
    padding-top: 4px;
  }
  .v-pb-4-ph {
    padding-bottom: 4px;
  }
  .v-pr-4-ph {
    padding-right: 4px;
  }
  .v-pl-4-ph {
    padding-left: 4px;
  }
  .v-mt-4-ph {
    margin-top: 4px;
  }
  .v-mb-4-ph {
    margin-bottom: 4px;
  }
  .v-ml-4-ph {
    margin-left: 4px;
  }
  .v-mr-4-ph {
    margin-right: 4px;
  }
  .v-pt-5-ph {
    padding-top: 5px;
  }
  .v-pb-5-ph {
    padding-bottom: 5px;
  }
  .v-pr-5-ph {
    padding-right: 5px;
  }
  .v-pl-5-ph {
    padding-left: 5px;
  }
  .v-mt-5-ph {
    margin-top: 5px;
  }
  .v-mb-5-ph {
    margin-bottom: 5px;
  }
  .v-ml-5-ph {
    margin-left: 5px;
  }
  .v-mr-5-ph {
    margin-right: 5px;
  }
  .v-pt-8-ph {
    padding-top: 8px;
  }
  .v-pb-8-ph {
    padding-bottom: 8px;
  }
  .v-pr-8-ph {
    padding-right: 8px;
  }
  .v-pl-8-ph {
    padding-left: 8px;
  }
  .v-mt-8-ph {
    margin-top: 8px;
  }
  .v-mb-8-ph {
    margin-bottom: 8px;
  }
  .v-ml-8-ph {
    margin-left: 8px;
  }
  .v-mr-8-ph {
    margin-right: 8px;
  }
  .v-pt-10-ph {
    padding-top: 10px;
  }
  .v-pb-10-ph {
    padding-bottom: 10px;
  }
  .v-pr-10-ph {
    padding-right: 10px;
  }
  .v-pl-10-ph {
    padding-left: 10px;
  }
  .v-mt-10-ph {
    margin-top: 10px;
  }
  .v-mb-10-ph {
    margin-bottom: 10px;
  }
  .v-ml-10-ph {
    margin-left: 10px;
  }
  .v-mr-10-ph {
    margin-right: 10px;
  }
  .v-pt-12-ph {
    padding-top: 12px;
  }
  .v-pb-12-ph {
    padding-bottom: 12px;
  }
  .v-pr-12-ph {
    padding-right: 12px;
  }
  .v-pl-12-ph {
    padding-left: 12px;
  }
  .v-mt-12-ph {
    margin-top: 12px;
  }
  .v-mb-12-ph {
    margin-bottom: 12px;
  }
  .v-ml-12-ph {
    margin-left: 12px;
  }
  .v-mr-12-ph {
    margin-right: 12px;
  }
  .v-pt-14-ph {
    padding-top: 14px;
  }
  .v-pb-14-ph {
    padding-bottom: 14px;
  }
  .v-pr-14-ph {
    padding-right: 14px;
  }
  .v-pl-14-ph {
    padding-left: 14px;
  }
  .v-mt-14-ph {
    margin-top: 14px;
  }
  .v-mb-14-ph {
    margin-bottom: 14px;
  }
  .v-ml-14-ph {
    margin-left: 14px;
  }
  .v-mr-14-ph {
    margin-right: 14px;
  }
  .v-pt-16-ph {
    padding-top: 16px;
  }
  .v-pb-16-ph {
    padding-bottom: 16px;
  }
  .v-pr-16-ph {
    padding-right: 16px;
  }
  .v-pl-16-ph {
    padding-left: 16px;
  }
  .v-mt-16-ph {
    margin-top: 16px;
  }
  .v-mb-16-ph {
    margin-bottom: 16px;
  }
  .v-ml-16-ph {
    margin-left: 16px;
  }
  .v-mr-16-ph {
    margin-right: 16px;
  }
  .v-pt-24-ph {
    padding-top: 24px;
  }
  .v-pb-24-ph {
    padding-bottom: 24px;
  }
  .v-pr-24-ph {
    padding-right: 24px;
  }
  .v-pl-24-ph {
    padding-left: 24px;
  }
  .v-mt-24-ph {
    margin-top: 24px;
  }
  .v-mb-24-ph {
    margin-bottom: 24px;
  }
  .v-ml-24-ph {
    margin-left: 24px;
  }
  .v-mr-24-ph {
    margin-right: 24px;
  }
  .v-pt-32-ph {
    padding-top: 32px;
  }
  .v-pb-32-ph {
    padding-bottom: 32px;
  }
  .v-pr-32-ph {
    padding-right: 32px;
  }
  .v-pl-32-ph {
    padding-left: 32px;
  }
  .v-mt-32-ph {
    margin-top: 32px;
  }
  .v-mb-32-ph {
    margin-bottom: 32px;
  }
  .v-ml-32-ph {
    margin-left: 32px;
  }
  .v-mr-32-ph {
    margin-right: 32px;
  }
  .v-pt-44-ph {
    padding-top: 44px;
  }
  .v-pb-44-ph {
    padding-bottom: 44px;
  }
  .v-pr-44-ph {
    padding-right: 44px;
  }
  .v-pl-44-ph {
    padding-left: 44px;
  }
  .v-mt-44-ph {
    margin-top: 44px;
  }
  .v-mb-44-ph {
    margin-bottom: 44px;
  }
  .v-ml-44-ph {
    margin-left: 44px;
  }
  .v-mr-44-ph {
    margin-right: 44px;
  }
  .v-pt-48-ph {
    padding-top: 48px;
  }
  .v-pb-48-ph {
    padding-bottom: 48px;
  }
  .v-pr-48-ph {
    padding-right: 48px;
  }
  .v-pl-48-ph {
    padding-left: 48px;
  }
  .v-mt-48-ph {
    margin-top: 48px;
  }
  .v-mb-48-ph {
    margin-bottom: 48px;
  }
  .v-ml-48-ph {
    margin-left: 48px;
  }
  .v-mr-48-ph {
    margin-right: 48px;
  }
  .v-pt-64-ph {
    padding-top: 64px;
  }
  .v-pb-64-ph {
    padding-bottom: 64px;
  }
  .v-pr-64-ph {
    padding-right: 64px;
  }
  .v-pl-64-ph {
    padding-left: 64px;
  }
  .v-mt-64-ph {
    margin-top: 64px;
  }
  .v-mb-64-ph {
    margin-bottom: 64px;
  }
  .v-ml-64-ph {
    margin-left: 64px;
  }
  .v-mr-64-ph {
    margin-right: 64px;
  }
  .v-pt-82-ph {
    padding-top: 82px;
  }
  .v-pb-82-ph {
    padding-bottom: 82px;
  }
  .v-pr-82-ph {
    padding-right: 82px;
  }
  .v-pl-82-ph {
    padding-left: 82px;
  }
  .v-mt-82-ph {
    margin-top: 82px;
  }
  .v-mb-82-ph {
    margin-bottom: 82px;
  }
  .v-ml-82-ph {
    margin-left: 82px;
  }
  .v-mr-82-ph {
    margin-right: 82px;
  }
  .v-pt-96-ph {
    padding-top: 96px;
  }
  .v-pb-96-ph {
    padding-bottom: 96px;
  }
  .v-pr-96-ph {
    padding-right: 96px;
  }
  .v-pl-96-ph {
    padding-left: 96px;
  }
  .v-mt-96-ph {
    margin-top: 96px;
  }
  .v-mb-96-ph {
    margin-bottom: 96px;
  }
  .v-ml-96-ph {
    margin-left: 96px;
  }
  .v-mr-96-ph {
    margin-right: 96px;
  }
  .v-mx-auto-ph {
    margin-left: auto;
    margin-right: auto;
  }
  .v-mx-auto-left-ph {
    margin-left: auto;
    margin-right: initial;
  }
  .v-mx-auto-right-ph {
    margin-left: initial;
    margin-right: left;
  }
  .v-mx-none-ph {
    margin-left: initial;
    margin-right: initial;
  }
  .v-mx-0-ph {
    margin-left: 0;
    margin-right: 0;
  }
  .v-px-0-ph {
    padding-left: 0;
    padding-right: 0;
  }
  .v-w-100-ph {
    width: 100%;
  }
  .v-w-auto-ph {
    width: auto;
    max-width: none;
  }
  .v-h-56-ph {
    height: 56px;
  }
  .v-h-100-ph {
    height: 100%;
  }
  .v-h-auto-ph {
    height: auto;
  }
  .v-ws-nowrap-ph {
    white-space: nowrap;
  }
  .v-ws-wrap-ph {
    white-space: normal;
  }
  .v-fz-12-ph {
    font-size: 12px;
  }
  .v-fz-16-ph {
    font-size: 16px;
  }
  .v-fz-24-ph {
    font-size: 24px;
  }
  .v-fz-38-ph {
    font-size: 38px;
  }
  .v-fz-48-ph {
    font-size: 48px;
  }
  .v-pos-relative-ph {
    position: relative;
  }
  .v-pos-absolute-ph {
    position: absolute;
  }
  .v-pos-fixed-ph {
    position: fixed;
  }
  .v-pos-initial-ph {
    position: initial;
  }
  .v-zi-0-ph {
    z-index: 0;
  }
  .v-zi-5-ph {
    z-index: 5;
  }
  .v-zi-10-ph {
    z-index: 10;
  }
  .v-zi-15-ph {
    z-index: 15;
  }
  .v-zi-20-ph {
    z-index: 20;
  }
  .v-brad-1000-ph {
    border-radius: 1000px;
  }
  .v-brad-100-ph {
    border-radius: 100px;
  }
  .v-brad-24-ph {
    border-radius: 24px;
  }
  .v-brad-16-ph {
    border-radius: 16px;
  }
  .v-brad-12-ph {
    border-radius: 12px;
  }
  .v-brad-8-ph {
    border-radius: 8px;
  }
  .v-brad-4-ph {
    border-radius: 4px;
  }
  .v-icon-16-ph {
    width: 16px;
    height: 16px;
  }
  .v-icon-20-ph {
    width: 20px;
    height: 20px;
  }
  .v-icon-24-ph {
    width: 24px;
    height: 24px;
  }
  .v-icon-40-ph {
    width: 40px;
    height: 40px;
  }
}
/*
 * --- ELEMENTS BASIC ---------------------------
 */
.v-icon {
  flex: none;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.v-icon .ico {
  width: 100%;
  height: 100%;
}
.v-pic-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.v-pic-bg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.v-img-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.v-img-contain img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.v-media-ratio {
  overflow: hidden;
  position: relative;
  width: 100%;
}
.v-media-ratio img,
.v-media-ratio iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.v-media-ratio.is-1 {
  padding-top: 100%;
}
.v-media-ratio.is-169 {
  padding-top: 56.25%;
}
.v-media-ratio.is-43 {
  padding-top: 75%;
}
.v-media-ratio.is-53 {
  padding-top: 60%;
}
.v-tab {
  display: none;
  opacity: 0;
}
.v-tab.is-active {
  display: block;
  animation: tab-show 1s linear 0.01s 1 forwards;
}
.v-ul-clear {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.v-status-success {
  color: #15803d;
}
.v-status-error {
  color: #ef4444;
}
/*
* --- FORM --------------------------------------
*/
.v-form .v-form-result-success,
.v-form .v-form-result-error {
  display: none;
}
.v-form.is-success .v-form-result-success {
  display: block;
}
.v-form.is-error .v-form-result-error {
  display: block;
}
.v-form .error-message {
  font-size: 0.7em;
  color: #ef4444;
}
.v-fg {
  position: relative;
}
.v-fg .error-message {
  position: absolute;
  bottom: 3px;
  right: 5px;
}
.v-fg.is-cbx .v-status-error {
  bottom: auto;
  top: 100%;
  right: 0;
}
.v-select select {
  appearance: none;
}
.v-select select::-ms-expand {
  display: none;
}
.v-input::placeholder {
  opacity: 0.4;
}
.v-field-icon {
  top: 0;
  left: 0;
}
.v-cbx {
  position: relative;
}
.v-cbx input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}
.v-cbx-wrapper {
  cursor: pointer;
}
.v-cbx-box {
  width: 1em;
  height: 1em;
  border: 1px solid #ff5c00;
}
input:checked ~ .v-cbx-box {
  background-color: #ff5c00;
}
.v-cbx.is-radio .v-cbx-box {
  border-radius: 100px;
  width: 18px;
  height: 18px;
}
.v-btn.v-bg-accent:hover {
  background: #bf4500;
}
/*
* --- ELEMENTS ----------------------------------
*/
.v-burger {
  width: 32px;
  height: 24px;
  position: relative;
}
.v-burger:before,
.v-burger:after {
  content: '';
  display: block;
  position: absolute;
}
.v-burger:before,
.v-burger:after,
.v-burger span {
  width: 100%;
  height: 4px;
  left: 0;
  background: #f00;
  border-radius: 10px;
  transition: all 0.32s linear;
}
.v-burger:before {
  top: 0;
}
.v-burger:after {
  top: 100%;
  margin-top: -4px;
}
.v-burger span {
  top: 50%;
  margin-top: 0;
}
.v-burger.is-active span {
  opacity: 0;
}
.v-burger.is-active:before,
.v-burger.is-active:after {
  top: 50%;
  margin-top: -2px;
}
.v-burger.is-active:before {
  transform: rotate(45deg);
}
.v-burger.is-active:after {
  transform: rotate(-45deg);
}
.link {
  transition: all 0.32s linear;
}
.link:hover {
  opacity: 0.6;
}
.v-link-zoom {
  cursor: zoom-in;
}
.v-fw-600 {
  font-weight: 600;
}
.v-fw-400 {
  font-weight: normal;
}
.v-lh-15 {
  line-height: 1.5;
}
.v-lh-normal {
  line-height: normal;
}
.v-ls-015 {
  letter-spacing: -0.015em;
}
.v-ls-025 {
  letter-spacing: -0.025em;
}
.v-lh-048 {
  letter-spacing: -0.048px;
}
.v-bg-dark {
  background: #252525;
}
.v-bg2 {
  background: #f5f5f5;
}
.v-bg-light {
  background: #fff;
}
.v-bg-light-012 {
  background: rgba(255,255,255,0.12);
}
.v-bg-light-008 {
  background: rgba(255,255,255,0.08);
}
.v-bg-accent {
  background: #ff5c00;
}
.v-color-text {
  color: #000;
}
.v-color-light {
  color: #fff;
}
.v-fill-text {
  fill: #000;
}
.v-fill-light {
  fill: #fff;
}
.v-opacity-032 {
  opacity: 0.32;
}
.v-opacity-04 {
  opacity: 0.4;
}
.v-opacity-048 {
  opacity: 0.48;
}
.v-opacity-056 {
  opacity: 0.56;
}
.v-opacity-064 {
  opacity: 0.64;
}
.v-color-accent {
  color: #ff5c00;
}
.v-txt p:not(:last-child) {
  margin-bottom: 0.6em;
}
.v-link-sm {
  background: rgba(255,255,255,0.08);
}
.v-link-sm:hover {
  background: #ff5c00;
}
.v-link-sm:hover .v-icon {
  opacity: 1;
}
@-moz-keyframes loading-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loading-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loading-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loading-rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-moz-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes tab-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/*
 * === HEADER ===================================
 */
.hLogo img {
  max-width: 300px;
}
@media all and (max-width: 767px) {
  .hLogo img {
    max-width: 180px;
  }
}
/*
 * === FOOTER ===================================
 */
.fLogo {
  max-width: 180px;
}
/*
 * === UNIT =====================================
 */
.hero {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url("../images/hero-bg.jpg");
}
.labelUnder {
  background: rgba(255,255,255,0.1);
  padding: 4px 8px 4px 4px;
}
.labelUnder .v-icon {
  background: rgba(255,255,255,0.3);
  fill: #fff;
}
.labelUnder .v-icon .ico {
  width: 50%;
}
.featureCard {
  box-shadow: 0 0 16px 0 rgba(0,0,0,0.04);
}
.featureCard-txt {
  color: rgba(0,0,0,0.4);
}
.featureCard-txt strong {
  color: rgba(0,0,0,0.88);
  font-weight: 600;
}
.featureCard .v-icon {
  fill: rgba(0,0,0,0.88);
}
