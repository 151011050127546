trunc($lines)
    display: box;
    display: -ms-box;
    display: -webkit-box;
    display: -moz-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: $lines;
    box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -ms-box-orient: vertical;

truncno()
    -webkit-line-clamp: none;
    display: block;
    text-overflow: clip;
    overflow: visible;
    min-height: auto;

transit($duration = 0.32s)
    transition: all $duration linear;

pseudo()
    content: '';
    display: block;
    position: absolute;

bg($position, $size)
    background-repeat: no-repeat;
    background-position: $position;
    background-size: $size;

size($wsize, $hsize)
    width: $wsize;
    height: $hsize;

font($size, $lineheight)
    font-size: unit($size, 'px');
    line-height: round(($lineheight / $size), 2);
