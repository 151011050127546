/*!
 * === UI =======================================
 */
.is-loading
    position: relative;

    &:before
        pseudo();
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: rgba(#fff, 0.5);
        z-index: 10;

    &:after
        pseudo();
        width: 40px;
        height: 40px;
        border-radius: 100%;
        top: 50%;
        left: 50%;
        margin-top: -20px;
        margin-left: -20px;
        border-width: 5px;
        border-color: #111827 #E5E7EB;
        border-style: solid;
        animation: loading-rotate 1s ease-in-out infinite forwards;
        z-index: 15;

@keyframes loading-rotate
    from
        transform: rotate(0);
    to
        transform: rotate(360deg);

@import 'framework';


/*!
 * --- ELEMENTS BASIC ---------------------------
 */

.v-icon
    flex: none;
    bg(center, contain);

    .ico
        size(100%, 100%);

.v-pic-bg
    position: absolute;
    size(100%, 100%);
    top: 0;
    left: 0;

    img
        size(100%, 100%);
        object-fit: cover;

.v-img-cover
    img
        size(100%, 100%);
        object-fit: cover;

.v-img-contain
    img
        size(100%, 100%);
        object-fit: contain;

.v-media-ratio
    overflow: hidden;
    position: relative;
    width: 100%;

    img, iframe
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

    &.is-1
        padding-top: 100%;

    &.is-169
        padding-top: round((9 / 16) * 100%, 2);

    &.is-43
        padding-top: round((3 / 4) * 100%, 2);

    &.is-53
        padding-top: round((3 / 5) * 100%, 2);

.v-tab
    display: none;
    opacity: 0;

    &.is-active
        display: block;
        animation: tab-show 1s linear 0.01s 1 forwards;

@keyframes tab-show
    from
        opacity: 0;
    to
        opacity: 1;

.v-ul-clear
    padding: 0;
    margin: 0;
    list-style-type: none;

.v-status-success
    color: $colorSuccess;

.v-status-error
    color: $colorError;


/*!
* --- FORM --------------------------------------
*/
.v-form
    .v-form-result-success,
    .v-form-result-error
        display: none;

    &.is-success .v-form-result-success
        display: block;

    &.is-error .v-form-result-error
        display: block;

    .error-message
        font-size: 0.7em;
        color: $colorError;

.v-fg
    position: relative;

    .error-message
        position: absolute;
        bottom: 3px;
        right: 5px;

    &.is-cbx .v-status-error
        bottom: auto;
        top: 100%;
        right: 0;

.v-select
    select
        appearance: none;

        &::-ms-expand
            display: none;

.v-input
    &::placeholder
        opacity: 0.4;

.v-field
    &-icon
        top: 0;
        left: 0;

.v-cbx
    position: relative;

    input
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;

    &-wrapper
        cursor: pointer;

    &-box
        size(1em, 1em);
        border: 1px solid $colorAccent;

    input:checked ~ &-box
        background-color: $colorAccent;

    &.is-radio &-box
        border-radius: 100px;
        size(18px, 18px);

.v-btn
    &.v-bg-accent
        &:hover
            background: darken($colorAccent,25%);


/*!
* --- ELEMENTS ----------------------------------
*/
.v-burger
    size(32px, 24px);
    position: relative;

    &:before, &:after
        pseudo();

    &:before, &:after, span
        width: 100%;
        height: 4px;
        left: 0;
        background: red;
        border-radius: 10px;
        transit();

    &:before
        top: 0;

    &:after
        top: 100%;
        margin-top: -4px;

    span
        top: 50%;
        margin-top: 0;

    &.is-active
        span
            opacity: 0;

        &:before, &:after
            top: 50%;
            margin-top: -2px;

        &:before
            transform: rotate(45deg);

        &:after
            transform: rotate(-45deg);

.link
    transit();

    &:hover
        opacity: 0.6;

.v-link-zoom
    cursor: zoom-in;

.v-fw-600
    font-weight: 600;

.v-fw-400
    font-weight: normal;

.v-lh-15
    line-height: 1.5;

.v-lh-normal
    line-height: normal;

.v-ls-015
    letter-spacing: -0.015em;

.v-ls-025
    letter-spacing: -0.025em;

.v-lh-048
    letter-spacing: -0.048px;

.v-bg-dark
    background: $colorBg;

.v-bg2
    background: $colorBg2;

.v-bg-light
    background: $colorBgLight;

.v-bg-light-012
    background: rgba($colorBgLight,0.12);

.v-bg-light-008
    background: rgba($colorBgLight,0.08);

.v-bg-accent
    background: $colorAccent;

.v-color-text
    color: $colorText;

.v-color-light
    color: $colorTextLight;

.v-fill-text
    fill: $colorText;

.v-fill-light
    fill: $colorBgLight;

.v-opacity-032
    opacity: 0.32;

.v-opacity-04
    opacity: 0.4;

.v-opacity-048
    opacity: 0.48;

.v-opacity-056
    opacity: 0.56;

.v-opacity-064
    opacity: 0.64;

.v-color-accent
    color: $colorAccent;

.v-txt
    p:not(:last-child)
        margin-bottom: 0.6em;

.v-link-sm
    background: rgba($colorBgLight,0.08);

    &:hover
        background: $colorAccent;

        .v-icon
            opacity: 1;
